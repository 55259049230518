<template>
  <BContainer fluid>
    <div>
      <BContainer>
        <BRow>
          <BCol>
            <span class="font-italic">
              {{ serialType }} Title History for
            </span>
          </BCol>
        </BRow>
        <BRow>
          <BCol>
            <h1>
              <!-- <a
                :href="profileUrl"
                class="text-ams-orange"
              >
                {{ name }}
              </a> -->
              <BLink
                :to="profileRoute"
                class="text-ams-orange min-accessibility-spacing"
              >
                {{ name }}
              </BLink>
            </h1>
          </BCol>
        </BRow>

        <BRow class="flex-md-row">
          <BCol>
            <div
              v-for="(keyId, index) in orderedSerials"
              :key="index"
              class="timeline"
            >
              <div class="timeline-obj">
                <HistoryCard
                  :serial-type="orderedSerials[index].serialType"
                  :basic-info="orderedSerials[index]"
                />
              </div>
            </div>
          </BCol>
        </BRow>
      </BContainer>
    </div>
  </BContainer>
</template>
<script>
// import URLGen from '@/utils/url-gen'
import { formatIssueStr, formatPublicationSearchQuery, parseParams, uniqueArray } from '@/utils'

import { JournalAPI, SeriesAPI } from '@/js-api'
import { updateMathJax } from '@/utils/utils'
import { trackJournalCounter5 } from '@/counter5-tracker'

export default {
  title: 'Title History',
  components: {
    HistoryCard: () => import('@/components/history/HistoryCard.vue'),
  },
  data() {
    return {
      name: 'Loading...',
      abbr: '',
      // basicInfo: {},
      // related: {},
      // diffs: {},
      serials: {},

      groupId: -1,
      journalId: -1,
      seriesId: -1,

    }
  },

  watch: {
    orderedSerials: function() {
      this.orderedSerials.forEach(element => {
        trackJournalCounter5({
          id: element.journalId,
          title: element.title,
        })
      })
    },
  },

  computed: {
    isSeries: function() {
      return (this.seriesId && this.seriesId > -1)
    },
    serialType: function() {
      return this.isSeries ? 'Series' : 'Journal'
    },
    // profileUrl: function() {
    //   const urlInfo = {}
    //   if (this.isSeries) {
    //     urlInfo.seriesId = this.seriesId
    //   } else {
    //     if (this.groupId > -1) {
    //       urlInfo.groupId = this.groupId
    //     } else {
    //       urlInfo.journalId = this.journalId
    //     }
    //   }
    //   return URLGen.profile(urlInfo)
    // },
    profileRoute: function() {
      const route = {
        name: 'SerialProfile',
      }
      if (this.isSeries) {
        route.query = {
          seriesId: this.seriesId,
        }
      } else {
        if (this.groupId > -1) {
          route.query = {
            groupId: this.groupId,
          }
        } else {
          route.query = {
            journalId: this.journalId,
          }
        }
      }
      return route
    },
    orderedSerials: function() {
      // let serialObjList = []
      // for(let i in this.serials) {
      //   serialObjList.push(this.serials[i])
      // }
      // // Replace this year based sort with a relation based sort?
      // // groupId=4136 changes everything between the oldest two, but it doesn't show changes
      // let sortedSerials = serialObjList.sort(
      //   function(a, b){
      //     if (a.latest.year > b.latest.year){
      //       return -1
      //     }
      //     if (a.latest.year < b.latest.year){
      //       return 1
      //     }
      //     return 0
      //   }
      // )
      const serialIds = Object.keys(this.serials)
      if (serialIds.length === 0) {
        return
      }
      const sortedSerialIds = [serialIds[0]]
      // Find what's older
      let current = this.serials[serialIds[0]]
      while (current && current.related && current.related.filter(x => x.reltype === 'Formerly').length > 0) {
        const relation = current.related.filter(x => x.reltype === 'Formerly')[0]
        const isSeries = 'relkey' in relation && relation.relkey === 'SER'
        const id = this.genRelId(relation.relid, isSeries)
        sortedSerialIds.push(id)
        current = this.serials[id]
      }
      current = this.serials[serialIds[0]]
      // Find what's newer
      while (current && current.related && current.related.filter(x => x.reltype === 'Continued as').length > 0) {
        const relation = current.related.filter(x => x.reltype === 'Continued as')[0]
        const isSeries = 'relkey' in relation && relation.relkey === 'SER'
        const id = this.genRelId(relation.relid, isSeries)
        sortedSerialIds.unshift(id) // Appends to the front of the order
        current = this.serials[id]
      }

      const sortedSerials = sortedSerialIds.map(x => this.serials[x])
      if (sortedSerials.includes(undefined)) {
        // Not fully loaded yet
        // OR there's a journalGroupID === null
        return
      }
      // Calculate related
      const keys = ['title', 'abbr', 'publisher', 'frequency', 'issn', 'eissn']
      for (let i = 0; i < sortedSerials.length; i++) {
        if (i + 1 < sortedSerials.length) {
          const older = sortedSerials[i + 1]

          const diffs = {}
          for (const k in keys) {
            if (keys[k] in older) {
              let isDiff = false
              const olderVal = older[keys[k]]
              const newerVal = sortedSerials[i][keys[k]]
              if (Array.isArray(olderVal) && Array.isArray(newerVal)) {
                if (olderVal.length !== newerVal.length) {
                  isDiff = true
                } else {
                  for (const a in olderVal) {
                    if (olderVal[a] !== newerVal[a]) {
                      isDiff = true
                      break
                    }
                  }
                }
              } else {
                isDiff = olderVal !== newerVal
              }

              if (isDiff) {
                diffs[keys[k]] = olderVal
              }
            }
          }
          sortedSerials[i].diffs = diffs
        }
      }

      return sortedSerials
    },
  },
  mounted: function() {
    this.onMount()
  },
  updated: function() {
    if (window.location.hash.length > 0) {
      document.getElementById(window.location.hash.substr(1)).scrollIntoView()
      location.href = window.location.hash // Used to set the :target css flag
    }
    updateMathJax()
  },
  methods: {
    onMount: async function() {
      document.title = 'Title History - MathSciNet'
      const uri = window.location.search.substring(1)
      const results = parseParams(uri)

      const keys = Object.keys(results)
      for (const i in keys) {
        if (this[keys[i]] === undefined || this[keys[i]] === -1) {
          this[keys[i]] = results[keys[i]]
        }
      }

      // Params parsed, check if you need to get groupId
      if (this.seriesId <= 0 && (!this.groupId || this.groupId <= 0)) {
        // Check if journalId provided
        if (this.journalId !== undefined) {
          // Modify the current url to have the groupId
          this.oldJourId = this.journalId
          this.groupId = await JournalAPI.getGroupId(this.journalId)
          // this.jourId = response.data.data.details[0].jourid
          // When there's only a groupId, jourId should be set by the results from Details
          this.onMount()
        }
        return // No ID provided
      }

      if (this.isSeries) {
        this.getDetails(this.seriesId, false)
      } else {
        this.getDetails(this.groupId, true)
      }
    },

    genRelId(id, isSerial) {
      return isSerial ? 'S' + id : 'J' + id
    },

    checkRelated: function() {
      // Look through all the related and see what's here and what's not
      const sequentialRelType = ['Continued as', 'Formerly']

      const relatedJournals = []
      const relatedSeries = []
      const existingJournals = []
      const existingSeries = []
      // Would like to use Javascript Sets, but those are aren't available in IE <11
      // https://caniuse.com/#feat=mdn-javascript_builtins_set_set
      // Currently 93% of users can use it, but we're holding onto IE support

      for (const i in this.serials) {
        // Save the existing id
        if (this.serials[i].seriesId && existingSeries.indexOf(this.serials[i].seriesId) === -1) {
          existingSeries.push(this.serials[i].seriesId)
        }
        if (this.serials[i].journalId && existingJournals.indexOf(this.serials[i].journalId) === -1) {
          existingJournals.push(this.serials[i].journalId)
        }

        // Save the related id
        const related = this.serials[i].related
        for (const r in related) {
          if (sequentialRelType.indexOf(related[r].reltype) === -1) {
            continue
          }
          const id = related[r].relid
          if (related[r].relkey === 'SER') {
            if (relatedSeries.indexOf(id) === -1) {
              relatedSeries.push(id)
            }
          } else {
            if (relatedJournals.indexOf(id) === -1) {
              relatedJournals.push(id)
            }
          }
        }
      }

      for (const i in relatedJournals) {
        if (existingJournals.indexOf(relatedJournals[i]) === -1) {
          // Found a needed journal, which might have other needed journals in it's group
          this.getGroupIdThenDetails(relatedJournals[i])
          return
        }
      }
      for (const i in relatedSeries) {
        if (existingSeries.indexOf(relatedSeries[i]) === -1) {
          // console.log('found a series!')
          this.getDetails(relatedSeries[i], false)
          return
        }
      }
    },

    getGroupIdThenDetails: async function(journalId) {
      const groupId = await JournalAPI.getGroupId(journalId)
      if (groupId !== null) {
        this.getDetails(groupId, true)
      } else {
        // DB Error. Remove this journal from results
        // Remove it from relations
        const keys = Object.keys(this.serials)
        for (const i in keys) {
          const key = keys[i]
          const matchIndex = this.serials[key].related.findIndex(x => x.relid === journalId)
          if (matchIndex > -1) {
            // Remove it
            this.serials[key].related.splice(matchIndex, 1)
          // } else {
          //   console.log('Did not match anything?')
          //   console.log(journalId)
          //   console.log(this.serials[key].related)
          //   console.log(this.serials[key].related.filter(x => x.relid === journalId))
          //   console.log(matchIndex)
          }
        }
      }
    },

    getPre85: async function(jourid, abbr) {
      const result = await JournalAPI.pre85Year(abbr)

      let newestPaperId = -1
      let oldestPaperId = -1
      if (result.newest.results.length > 0) {
        newestPaperId = result.newest.results[0].paperId
      }
      if (result.oldest.results.length > 0) {
        oldestPaperId = result.oldest.results[0].paperId
      }
      if (oldestPaperId === -1 && newestPaperId === -1) {
        // No years for this journal at all
        return
      }
      const newestyr = result.newest.results[0].pubyear[0].start
      const oldestyr = result.oldest.results[0].pubyear[0].start

      this.$set(this.serials[this.genRelId(jourid, false)], 'latest', {
        year: newestyr,
        text: 'MR' + newestPaperId,
        id: newestPaperId,
        // url: URLGen.document({ paperId: newestPaperId }),
        to: {
          name: 'ArticlePage',
          query: {
            mr: newestPaperId,
          },
        },
      })
      this.$set(this.serials[this.genRelId(jourid, false)], 'earliest', {
        year: oldestyr,
        text: 'MR' + oldestPaperId,
        id: oldestPaperId,
        // url: URLGen.document({ paperId: oldestPaperId }),
        to: {
          name: 'ArticlePage',
          query: {
            mr: oldestPaperId,
          },
        },
      })
    },

    getDetails: async function(id, isGroup) {
      let details
      if (isGroup) {
        details = await JournalAPI.details(id)
      } else {
        details = await SeriesAPI.details(id)
      }

      // Parse it into objects. Each object needs the following:
      if (isGroup) {
        const journals = details
        for (const i in journals) {
          const jour = journals[i]
          if (this.abbr.length === 0) {
            this.name = jour.jortitle
            document.title = 'Title History for ' + this.name + ' - MathSciNet'
            this.abbr = jour.ojorabbr
            this.setTitle(this.abbr)
          }
          let latest = {
            year: '-',
            text: 'N/A',
            id: null,
            url: '#',
          }
          let earliest = {
            year: '-',
            text: 'N/A',
            id: null,
            url: '#',
          }
          if (jour.newest.results.length > 0) {
            latest = {
              year: jour.newest.results[0].year,
              text: formatIssueStr(jour.newest.results[0]),
              query: `ji: ${jour.jourid} ` + formatPublicationSearchQuery(jour.newest.results[0]),
              id: jour.newest.results[0].issid,
              // url: URLGen.document({ issueId: jour.newest.results[0].issid }),
              to: {
                name: 'PublicationsSearch',
                query: {
                  query: `ji:${jour.jourid} iss:${jour.newest.results[0].issid}`,
                },
              },
            }
          }
          if (jour.oldest.results.length > 0) {
            earliest = {
              year: jour.oldest.results[0].year,
              text: formatIssueStr(jour.oldest.results[0]),
              query: `ji: ${jour.jourid} ` + formatPublicationSearchQuery(jour.oldest.results[0]),
              id: jour.oldest.results[0].issid,
              // url: URLGen.document({ issueId: jour.oldest.results[0].issid }),
              to: {
                name: 'PublicationsSearch',
                query: {
                  query: `ji:${jour.jourid} iss:${jour.oldest.results[0].issid}`,
                },
              },
            }
          }

          this.$set(this.serials, this.genRelId(jour.jourid, false), {
            groupId: jour.groupId,
            journalId: jour.jourid,
            seriesId: -1,
            serialType: 'journal',
            title: jour.jortitle,
            abbr: jour.ojorabbr,
            publisher: uniqueArray(jour.publisher), // journal group 33
            frequency: jour.freq,
            issn: jour.issn,
            eissn: jour.eissn,
            count: jour.totalIssues, // Number of Issues
            latest: latest,
            earliest: earliest,
            related: jour.related,
            current: !!(jour.active && jour.active === 'CURRENT'),
          })

          if (latest.year === '-' || earliest.year === '-') {
            this.getPre85(jour.jourid, jour.ojorabbr)
          }
        }
      } else {
        const series = details.results[0]
        if (this.abbr.length === 0) {
          this.name = series.title
          document.title = 'Title History for ' + this.name + ' - MathSciNet'
          this.abbr = series.shortTitle
          this.setTitle(this.abbr)
        }

        this.$set(this.serials, this.genRelId(series.serid, true), {
          groupId: -1,
          journalId: -1,
          seriesId: series.serid,
          serialType: 'series',
          title: series.title,
          abbr: series.shortTitle,
          publisher: uniqueArray(series.publisher),
          frequency: '',
          issn: series.issn,
          eissn: '',
          count: series.newest.total || series.oldest.total, // Number of Volumes
          latest: {
            year: series.newest.results[0].year,
            text: series.newest.results[0].year + ', ' + series.newest.results[0].title,
            query: `si: ${series.serid} ` + formatPublicationSearchQuery(series.newest.results[0]),
            id: series.newest.results[0].paperId,
            // url: URLGen.document({ volumeId: series.newest.results[0].paperId }),
            to: {
              name: 'ArticlePage',
              query: {
                mr: series.newest.results[0].paperId,
              },
            },
          },
          earliest: {
            year: series.oldest.results[0].year,
            text: series.oldest.results[0].year + ', ' + series.oldest.results[0].title,
            query: `si: ${series.serid} ` + formatPublicationSearchQuery(series.oldest.results[0]),
            id: series.oldest.results[0].paperId,
            // url: URLGen.document({ volumeId: series.oldest.results[0].paperId }),
            to: {
              name: 'ArticlePage',
              query: {
                mr: series.oldest.results[0].paperId,
              },
            },
          },
          related: series.related,
          current: !!(series.active && series.active === 'CURRENT'),
        })
      }

      this.checkRelated()
    },

    setTitle: function(text) {
      text = text.replace(/\$/g, '')
      document.title = text + ' - Title History'
    },
  },
}
</script>
<style>
@import '../assets/msn_vue.css';
@import '../assets/views/msn_serials_profile.css';
@import '../assets/views/msn_serials_history.css';
</style>
